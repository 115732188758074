import { adminRoute } from "@/pages/admin";
import { createRoute, lazyRouteComponent, Outlet, SearchSchemaInput } from "@tanstack/react-router";
import { fallback, InferOutput, number, object, optional, parse, pipe, toMinValue } from "valibot";

export const paymentsRoute = createRoute({
  path: "payments",
  getParentRoute: () => adminRoute,
  component: Outlet,
  beforeLoad: () => ({
    breadcrumb: "Card Payments",
  }),
});

const searchSchema = object({
  page: fallback(pipe(number(), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
});
type PartialSearchSchema = Partial<InferOutput<typeof searchSchema>>;

export const paymentsIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => paymentsRoute,
  component: lazyRouteComponent(() => import("./+PaymentsPage")),
  validateSearch: (search: PartialSearchSchema & SearchSchemaInput) => parse(searchSchema, search),
});
