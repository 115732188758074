import { getAllAccountsQuery } from "@/api/accountOwnership";
import { queryClient } from "@/api/utils";
import { adminRoute } from "@/pages/admin";
import { createRoute, lazyRouteComponent, Outlet, SearchSchemaInput } from "@tanstack/react-router";
import { fallback, InferOutput, number, object, optional, parse, picklist, pipe, string, toMinValue } from "valibot";

export const accountOwnersRoute = createRoute({
  path: "account-owners",
  getParentRoute: () => adminRoute,
  component: Outlet,
  beforeLoad: () => ({
    breadcrumb: "Account Owners",
  }),
});

const searchSchema = object({
  page: fallback(pipe(number(), toMinValue(1)), 1),
  "page-size": fallback(pipe(optional(number(), 100), toMinValue(10)), 100),
  type: fallback(optional(picklist(["supplier", "supporter"])), undefined),
  ownerId: fallback(optional(string()), undefined),
  country: fallback(optional(picklist(["NZ", "AU"])), undefined),
  q: optional(string()),
});
type PartialSearchSchema = Partial<InferOutput<typeof searchSchema>>;

export const accountOwnersIndexRoute = createRoute({
  path: "/",
  getParentRoute: () => accountOwnersRoute,
  component: lazyRouteComponent(() => import("./+AccountOwnersPage")),
  validateSearch: (search: PartialSearchSchema & SearchSchemaInput) => parse(searchSchema, search),
  loader: () => {
    queryClient.prefetchQuery(getAllAccountsQuery({ page: 1, pageSize: 10 }));
  },
});
