import { getErrorMessage } from "@/utils/error";
import { config } from "@common/utils/config";
import { toast } from "@givenwell/components";
import { createAuth } from "@givenwell/fusionauth";
import {
  AccountOwnerService,
  AirWallexService,
  AllowanceService,
  ApiError,
  AuthService,
  CronofyService,
  ListingService,
  LocationService,
  ManagementInvitationService,
  MediaService,
  MemberService,
  MetadataService,
  MgmtMessagesService,
  MgmtPurchaseService,
  MgmtSubscriptionsService,
  NotificationsService,
  OpenAPI,
  PaymentsService,
  PayoutsService,
  ReportingService,
  ResourceService,
  RoleService,
  SalesService,
  SupplierMarketsService,
  SupplierService,
  SupporterPayInService,
  SupporterReportingService,
  SupporterService,
  SupporterTransactionsService,
  UserService,
} from "@givenwell/management-api";
import { QueryCache, QueryClient, UseQueryOptions } from "@tanstack/react-query";

export const auth = createAuth({
  serverUrl: config.apiBaseUrl,
  redirectUri: config.manageUrl,
  logoutUri: config.manageUrl + "/sign-in",
  environmentName: config.environmentName,
  applicationName: "management",
});
OpenAPI.BASE = config.apiBaseUrl;
OpenAPI.TOKEN = auth.getAccessToken;

export const api = {
  accountOwner: AccountOwnerService,
  airWallex: AirWallexService,
  allowance: AllowanceService,
  auth: AuthService,
  cronofy: CronofyService,
  invitation: ManagementInvitationService,
  listings: ListingService,
  location: LocationService,
  media: MediaService,
  member: MemberService,
  metadata: MetadataService,
  payments: PaymentsService,
  mgmtMessages: MgmtMessagesService,
  mgmtPurchase: MgmtPurchaseService,
  notifications: NotificationsService,
  payouts: PayoutsService,
  resources: ResourceService,
  reporting: ReportingService,
  roles: RoleService,
  sales: SalesService,
  subscriptions: MgmtSubscriptionsService,
  supplier: SupplierService,
  supplierMarkets: SupplierMarketsService,
  supporter: SupporterService,
  supporterTransactions: SupporterTransactionsService,
  supporterPayIn: SupporterPayInService,
  supporterReporting: SupporterReportingService,
  user: UserService,
};

let refetchTokenStatus: "running" | "idle" = "idle";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error) {
      if (error instanceof ApiError && error.status === 401) {
        if (refetchTokenStatus === "running") {
          return;
        }
        refetchTokenStatus = "running";
        auth.stopAssumingRole();
        auth
          .refresh(true)
          .then(success => {
            if (success) {
              setTimeout(() => {
                api.auth
                  .getManagementUserInfo()
                  .then(() => {
                    queryClient.refetchQueries();
                  })
                  .catch(() => {
                    auth.logout();
                  });
              }, 100);
            } else {
              auth.logout();
            }
          })
          .finally(() => {
            // 30 sec grace period from attempting to refresh token before allowing another attempt.
            setTimeout(() => {
              refetchTokenStatus = "idle";
            }, 30 * 1000);
          });
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: Infinity,
      retry: false,
    },
    mutations: {
      onSuccess() {
        queryClient.invalidateQueries();
      },
      onError(error) {
        const errorMessage = getErrorMessage(error);
        toast.error("Something went wrong", {
          description: errorMessage,
        });
      },
    },
  },
});

export type SimpleUseQueryOptions<TData> = Partial<UseQueryOptions<TData, Error, TData, string[]>>;

export type FetchMethod = "CONNECT" | "DELETE" | "GET" | "HEAD" | "OPTIONS" | "PATCH" | "POST" | "PUT" | "TRACE";

export type FetchOptions = {
  method?: FetchMethod;
  headers?: Record<string, string>;
  body?: XMLHttpRequestBodyInit | Document | null | undefined;
  download?: {
    onProgress?: (progress: number) => void;
    onLoadEnd?: () => void;
    onLoadStart?: () => void;
  };
  upload?: {
    onProgress?: (progress: number) => void;
    onLoadEnd?: () => void;
    onLoadStart?: () => void;
  };
};

export function fetchWithProgress<TData>(input: URL | string, options?: FetchOptions) {
  return new Promise<TData>((resolve, reject) => {
    const request = new XMLHttpRequest();

    request.addEventListener("abort", e => {
      reject(e);
    });

    request.addEventListener("error", e => {
      reject(e);
    });

    request.addEventListener("load", e => {
      let response: any = null;
      try {
        response = JSON.parse(request.response) as TData;
      } catch {}
      if (request.status >= 200 && request.status < 300) {
        resolve(response);
      }
      reject(e);
    });

    request.addEventListener("loadstart", () => {
      options?.download?.onProgress?.(0);
      options?.download?.onLoadStart?.();
    });

    request.addEventListener("progress", e => {
      if (e.lengthComputable && e.total > 0) {
        const progress = Math.ceil(e.loaded / e.total);
        options?.download?.onProgress?.(progress);
      }
    });

    request.addEventListener("loadend", () => {
      options?.download?.onProgress?.(1);
      options?.download?.onLoadEnd?.();
    });

    request.upload.addEventListener("loadstart", () => {
      options?.upload?.onProgress?.(0);
      options?.upload?.onLoadStart?.();
    });

    request.upload.addEventListener("progress", e => {
      if (e.lengthComputable && e.total > 0) {
        const progress = Math.ceil(e.loaded / e.total);
        options?.upload?.onProgress?.(progress);
      }
    });

    request.upload.addEventListener("loadend", () => {
      options?.upload?.onProgress?.(1);
      options?.upload?.onLoadEnd?.();
    });

    request.open(options?.method || "GET", input);

    if (options?.headers) {
      Object.entries(options?.headers).forEach(([key, value]) => {
        request.setRequestHeader(key, value);
      });
    }

    request.send(options?.body);
  });
}
