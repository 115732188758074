import { api } from "@/api/utils";
import { UpdateAccountOwnerRequest } from "@givenwell/management-api";
import { queryOptions, useMutation } from "@tanstack/react-query";

type AutocompleteString<Options> = Options | (string & {});

export const getAllAccountsQuery = (req: {
  page: number;
  pageSize: number;
  accountName?: string;
  ownerId?: AutocompleteString<"NONE">;
  type?: "supplier" | "supporter";
  accountCountry?: "NZ" | "AU" | "SG";
}) => {
  return queryOptions({
    queryKey: ["accountOwner", req],
    queryFn: async () => {
      return await api.accountOwner.getAllAccounts(req);
    },
  });
};

export const getAvailableAccountOwnersQuery = () => {
  return queryOptions({
    queryKey: ["accountOwner"],
    queryFn: async () => {
      return await api.accountOwner.getAccountOwners();
    },
  });
};

export const useUpdateAccountOwnerMutation = () => {
  return useMutation({
    mutationFn: (args: { accountId: string; requestBody: UpdateAccountOwnerRequest }) =>
      api.accountOwner.updateAccountOwner(args),
  });
};
