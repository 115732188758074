import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { walletRoute } from "../..";

export const allowanceTransactionsRoute = createRoute({
  path: "allowance/$batchId",
  getParentRoute: () => walletRoute,
  beforeLoad: () => ({
    breadcrumb: "View Details",
  }),
  component: lazyRouteComponent(() => import("./+BatchDetails")),
});
